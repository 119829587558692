import React, { useState, useEffect } from "react";
import { NavBar, Footer } from "../../index.js";
import { Box, Typography, Button, Grid, Paper, Modal } from "@mui/material";
import { ThemeConfig } from "../../../config/theme.config.js";
import ScrollToTop from "../../../wrapper/scrollToTop.jsx";
import { getSummaryByCategory } from "../../../utils/summaries.js";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CustomProgress } from "./components/percentageIcon.svg";
import SummaryCard from "./components/SummaryCard.js";
import { getRolForField, getSizeForField } from "../../../utils/pdfHelpers.js";
import { useEmail } from "../EmailContext.jsx";
import { sendMailRequest } from "../../../lib/lecosyBackend.js";
import { useMemo } from "react";
import { Country, State } from "country-state-city";
const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: { xs: "90%", md: 400 },
   bgcolor: "#E8ECEF",
   border: "none",
   boxShadow: 24,
   p: 4,
   borderRadius: "20px",
   outline: "none",
};

export const ResultsScreen = () => {
   const [isButtonDisabled, setIsButtonDisabled] = useState(false);
   const location = useLocation();
   const navigate = useNavigate();
   const formData = useMemo(() => {
      return location.state?.formData || [];
   }, [location.state?.formData]);
   const { email } = useEmail();

   useEffect(() => {
      if (!formData.length) navigate("/diagnostico-gobernanza-empresarial/form");
      else if (!email) navigate("/diagnostico-gobernanza-empresarial");
   }, [formData, email, navigate]);

   const sectorEmpresaCategory = formData.find((item) => item.field === "sectorEmpresa")?.categoria || "A";
   const numeroEmpleadosCategory = formData.find((item) => item.field === "numeroEmpleados")?.categoria || "B";
   const tipoSociedadCategory = formData.find((item) => item.field === "tipoSociedad")?.categoria || "A";
   const decisionesColegiadasCategory =
      formData.find((item) => item.field === "decisionesColegiadas")?.categoria || "C";

   // Get summaries based on dynamic categories
   const sectorEmpresaSummary = getSummaryByCategory("sectorEmpresa", sectorEmpresaCategory);
   const numeroEmpleadosSummary = getSummaryByCategory("numeroEmpleados", numeroEmpleadosCategory);
   const tipoSociedadSummary = getSummaryByCategory("tipoSociedad", tipoSociedadCategory);
   const decisionesColegiadasSummary = getSummaryByCategory("decisionesColegiadas", decisionesColegiadasCategory);

   const [open, setOpen] = useState(false);

   const handleSend = async () => {
      setIsButtonDisabled(true);
      const formData = location.state?.formData || [];
      const name = formData.find((item) => item.field === "nombre")?.value || "Usuario";

      if (!email) {
         alert("Por favor, ingresa un correo.");
         setIsButtonDisabled(false);
         return;
      }

      //formData.push({ field: "email", value: email });

      // Formatear los valores de país y entidad federativa para que sean legibles
      const formattedFormData = formData.map((item) => {
         if (item.field === "pais") {
            const country = Country.getCountryByCode(item.value);
            return {
               ...item,
               value: country ? country.name : item.value, // Reemplazar con el nombre completo del país
            };
         }
         if (item.field === "entidadFederativa") {
            const estado = State.getStateByCodeAndCountry(item.value, formData.find((f) => f.field === "pais")?.value);
            return {
               ...item,
               value: estado ? estado.name : item.value, // Reemplazar con el nombre completo de la entidad federativa
            };
         }
         return item;
      });

      const emailSent = await sendMailRequest({ email, formData: formattedFormData, name });

      if (!emailSent) alert("Hubo un problema al enviar el correo.");

      openConfirmationModal();
      setIsButtonDisabled(false);
   };

   const openConfirmationModal = () => {
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
   };

   return (
      <ThemeConfig>
         <ScrollToTop>
            {/* <NavBar /> */}

            <Modal
               open={open}
               onClose={handleClose}
               aria-labelledby="confirmation-modal-title"
               aria-describedby="confirmation-modal-description"
            >
               <Box
                  sx={{
                     ...modalStyle,
                     width: { xs: "90%", sm: "70%", md: "500px" },
                     height: { xs: "auto", md: "auto" },
                     borderRadius: "12px",
                     p: { xs: 3, sm: 4 },
                     pb: { sm: 2, sm: 3 },
                  }}
               >
                  <Typography
                     id="confirmation-modal-title"
                     variant="h6"
                     component="h2"
                     sx={{
                        color: "#162C44",
                        fontWeight: "bold",
                        lineHeight: "1.2",
                        fontSize: "1.3rem",
                        pr: { sm: "2vw", md: 5 },
                     }}
                  >
                     Gracias por completar su diagnóstico de Gobernanza empresarial.
                  </Typography>
                  <Typography
                     id="confirmation-modal-description"
                     sx={{ mt: 1, lineHeight: "1.2", fontSize: "0.8rem", pr: { sm: "4vw", md: 8 } }}
                  >
                     En breve, estará recibiendo su informe completo con recomendaciones personalizadas basadas en sus
                     respuestas.
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 0.5, fontWeight: "bold" }}>
                     <Button onClick={handleClose} sx={{ px: 2, py: 0.5 }}>
                        Aceptar
                     </Button>
                  </Box>
               </Box>
            </Modal>
            <Grid container sx={{ minHeight: "100vh", backgroundColor: "white" }}>
               {/* Sidebar */}
               <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                     padding: 3,
                     position: "relative",
                     borderTopRightRadius: { xs: 0, md: 30 },
                     borderBottomRightRadius: { xs: 0, md: 30 },
                     overflow: "hidden",
                  }}
               >
                  <Box
                     sx={{
                        background: "linear-gradient(83.31deg, #162C44 -17.93%, #000000 117.34%)",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        borderTopRightRadius: { xs: 0, md: 30 },
                        borderBottomRightRadius: { xs: 0, md: 30 },
                     }}
                  />

                  <Box
                     sx={{
                        backgroundImage:
                           "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/sidebar.svg)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "bottom",
                        backgroundSize: "cover",
                        width: "100%",
                        height: { xs: "100%", md: "20%" },
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        zIndex: 2,
                     }}
                  />

                  <Box
                     sx={{
                        position: "relative",
                        zIndex: 3,
                        color: "#fff",
                     }}
                  >
                     <Box
                        onClick={() => navigate("/")}
                        component={"img"}
                        alt="Logo"
                        src={"https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy-blanco.png"}
                        sx={{
                           display: "flex",
                           height: 45,
                           ml: 2,
                           mt: 1,

                           "&:hover": {
                              cursor: "pointer",
                           },
                        }}
                     />
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "center",
                           mb: { xs: 2, md: 4 },
                           mt: 6,
                        }}
                     >
                        <Box
                           sx={{
                              position: "relative",
                              display: "inline-flex",
                              background:
                                 "linear-gradient(180deg, rgba(232, 236, 239, 0.15) -36.05%, rgba(255, 255, 255, 0.15) 84.68%)",
                              padding: 0.8,
                              boxShadow: "8px 8px 5.1px rgba(0, 0, 0, 0.25)",
                              borderRadius: "30px",
                              justifyContent: "center",
                              height: "265px",
                           }}
                        >
                           <CustomProgress
                              style={{
                                 width: "68%",
                                 color: "#f1f1f1",
                              }}
                           />
                           <Box
                              sx={{
                                 top: 0,
                                 left: 0,
                                 bottom: 0,
                                 right: 0,
                                 position: "absolute",
                                 display: "flex",
                                 flexDirection: "column",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 color: "#f1f1f1",
                              }}
                           >
                              <Typography
                                 variant="h3"
                                 component="div"
                                 color="inherit"
                                 fontWeight={"bold"}
                                 sx={{
                                    fontSize: { xs: "2.6rem", sm: "2.6rem", md: "3vw", lg: "2.6rem" },
                                    mt: 1,
                                 }}
                              >
                                 100%
                              </Typography>
                              <Typography
                                 variant="h9"
                                 component="div"
                                 color="inherit"
                                 sx={{ fontSize: { xs: "0.7rem", sm: "0.7rem", md: "0.9vw", lg: "0.7rem" }, mt: -0.5 }}
                              >
                                 Completado
                              </Typography>
                           </Box>
                        </Box>
                     </Box>

                     <Box
                        sx={{
                           mt: 4,
                           textAlign: "center",
                           position: "relative",
                           mx: { xs: "15px", sm: "30vw", md: "0.2vw", lg: 2 },
                        }}
                     >
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                           }}
                        >
                           <Box
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                              }}
                           >
                              <img
                                 src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/user.svg"
                                 alt="User Icon"
                                 style={{
                                    marginRight: 30,
                                    width: "38px",
                                    height: "38px",
                                    marginLeft: 6,
                                 }}
                              />
                           </Box>
                           <Typography variant="h7" sx={{ fontWeight: "bold", textAlign: "left" }}>
                              {formData.find((item) => item.field === "nombre")?.value || "Usuario"}
                           </Typography>
                        </Box>

                        <Box
                           sx={{
                              width: "4px",
                              backgroundColor: "transparent",
                              borderLeft: "2px dashed white",
                              height: { xs: "7px", sm: "7px", md: "100px" },
                              marginLeft: 3,
                              marginRight: "auto",
                           }}
                        />

                        <Box sx={{ display: "flex", alignItems: "center", mb: 1, mt: 1 }}>
                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                 src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/rol.svg"
                                 alt="Rol Icon"
                                 style={{
                                    width: "49px",
                                    height: "49px",
                                    marginRight: 30,
                                 }}
                              />
                           </Box>
                           <Typography variant="h7" sx={{ fontWeight: "bold", textAlign: "left" }}>
                              {getRolForField(formData.find((item) => item.field === "rolEmpresa")?.value || "")}
                           </Typography>
                        </Box>

                        <Box
                           sx={{
                              width: "4px",
                              backgroundColor: "transparent",
                              borderLeft: "2px dashed white",
                              height: { xs: "7px", sm: "7px", md: "100px" },
                              marginLeft: 3,
                              marginRight: "auto",
                           }}
                        />

                        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                 src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/companySize.svg"
                                 alt="Company Size Icon"
                                 style={{
                                    width: "51px",
                                    height: "51px",
                                    marginRight: 30,
                                 }}
                              />
                           </Box>
                           <Typography variant="h7" sx={{ fontWeight: "bold", textAlign: "left" }}>
                              {getSizeForField(
                                 formData.find((item) => item.field === "tamanoEmpresa")?.value ||
                                    "Tamaño de empresa no definido"
                              )}
                           </Typography>
                        </Box>
                     </Box>
                  </Box>
               </Grid>

               {/* Main content */}
               <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{
                     paddingTop: { xs: 6, md: 16 },
                     backgroundColor: "#FFFFFF",
                     color: "#162C44",
                  }}
               >
                  <Box>
                     <Box sx={{ paddingLeft: { xs: 2, md: 12 }, paddingRight: { xs: 2, md: 16 } }}>
                        <Typography
                           variant="h3"
                           sx={{
                              mb: 4,
                              color: "#162C44",
                              fontWeight: "bold",
                              paddingX: 3,
                           }}
                        >
                           Hola, {formData.find((item) => item.field === "nombre")?.value || "Usuario"}
                        </Typography>
                        <Typography
                           sx={{
                              mb: 4,
                              fontWeight: "bold",
                              paddingX: 3,

                              textAlign: { xs: "left", sm: "justify" },
                              fontSize: { xs: "1.5rem", sm: "1.5rem", md: "22px" },
                              lineHeight: "1.2",
                           }}
                        >
                           Con base en las respuestas que nos ha proporcionado en el cuestionario, hemos elaborado un
                           informe con recomendaciones personalizadas en materia de gobernanza empresarial.
                        </Typography>
                     </Box>
                     <Typography
                        sx={{
                           mb: 2,
                           paddingLeft: { xs: 5, md: 15 },

                           paddingY: 2,
                           color: "#748190",
                           backgroundColor: "#E8ECEF",
                           fontWeight: "bold",
                           width: { xs: "95%", md: "62%" },
                           borderTopRightRadius: 12,
                           borderBottomRightRadius: 12,
                           fontSize: "26px",
                           lineHeight: 1,
                        }}
                     >
                        Este es el resumen de sus resultados:
                     </Typography>
                     <Box />
                     <Box sx={{ paddingLeft: { xs: 2, md: 12 }, paddingRight: { xs: 2, md: 16 } }}>
                        <Paper sx={{ padding: 3, boxShadow: "none", lineHeight: "1.2" }}>
                           <SummaryCard
                              title="Debido al sector en el que se desempeña la empresa:"
                              summary={sectorEmpresaSummary}
                           />
                           <SummaryCard title="Por el número de colaboradores:" summary={numeroEmpleadosSummary} />
                           <SummaryCard title="En base al tipo de sociedad:" summary={tipoSociedadSummary} />
                           <SummaryCard
                              title="De acuerdo al proceso de toma de decisiones:"
                              summary={decisionesColegiadasSummary}
                           />
                        </Paper>
                        <Typography variant="body1" sx={{ mb: 6, paddingX: 3, color: "#8A95A0" }}>
                           Los resultados de este diagnóstico se basan en aproximaciones de las respuestas
                           proporcionadas.
                        </Typography>
                     </Box>
                  </Box>
                  <Box
                     sx={{
                        background: "rgba(232, 236, 239, 0.45)",
                        paddingLeft: { xs: 2, md: 12 },
                        paddingRight: { xs: 2, md: 16 },
                        paddingY: 8,
                     }}
                  >
                     <Box
                        sx={{
                           paddingX: 3,
                           backgroundColor: "none",
                           boxShadow: "none",
                        }}
                     >
                        <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold", color: "#162C44" }}>
                           ¿Quiere recibir su informe completo?
                        </Typography>

                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                              mb: 2,
                           }}
                        >
                           <Button
                              variant="contained"
                              sx={{
                                 backgroundColor: "#162C44",
                                 height: "auto",
                                 paddingY: 0.7,
                                 paddingX: 2.5,
                              }}
                              onClick={handleSend}
                              //disabled={isButtonDisabled}
                           >
                              <strong>Enviar por correo electrónico</strong>
                           </Button>
                        </Box>
                     </Box>
                     <Typography variant="body1" sx={{ mb: 4, paddingX: 3, textAlign: "justify" }}>
                        Para obtener un análisis más preciso y descubrir cómo nuestras soluciones pueden optimizar su
                        gobernanza corporativa, <strong>contáctenos para recibir asesoramiento personalizado</strong> y
                        adaptado a sus necesidades específicas.
                     </Typography>
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: { xs: "center", lg: "flex-end" },
                           width: "100%",
                           gap: 2,
                           mb: 2,
                        }}
                     >
                        <Button
                           sx={{
                              borderRadius: 3,
                              px: 3,
                              py: 1,
                              "&:hover": { bgcolor: "#5c6c7c" },
                           }}
                           onClick={() =>
                              window.open(
                                 "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2toeLcZcjF_xdL-qNc4muDlO4hXeIW2vwuCIxMNb5P6d2TsLh8xgEhM44PS6Wo6kJY_77D3Z21",
                                 "_blank"
                              )
                           }
                        >
                           <Typography fontFamily={"bold"}>Agendar sesión</Typography>
                        </Button>
                     </Box>
                  </Box>
               </Grid>
            </Grid>

            <Footer />
         </ScrollToTop>
      </ThemeConfig>
   );
};
