import * as React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import "../pages/gradientBoxes.css";

export const Footer = () => {
   return (
      <Box sx={{ display: "flex" }}>
         <Box
            className="gradientBox90"
            sx={{
               width: "100%",
               display: "flex",
               flexDirection: "column",
               color: "#fff",
               py: 1,
            }}
         >
            <Grid
               container
               sx={{
                  mt: { xs: 3, md: 4 },
                  mb: 3,
                  //justifyContent: { xs: "center", md: "flex-start", lg: "space-between" },
               }}
            >
               <Grid
                  item
                  xs={6}
                  md={4}
                  lg={5}
                  sx={{
                     pl: { xs: 0, md: 15 },
                     display: "flex",
                     alignItems: "center",
                  }}
               >
                  <Grid
                     container
                     sx={{
                        width: { xs: "100%", md: "90%", lg: "30%" },
                        px: { xs: 5, md: 0 },
                     }}
                  >
                     <Grid item xs={4}>
                        <a
                           href="https://www.facebook.com/lecosylegalcs"
                           style={{
                              color: "#fff",
                              textDecoration: "none",
                           }}
                           target={"_blank"}
                           rel="noreferrer noopener"
                           aria-label="Facebook"
                           alt={"Facebook"}
                        >
                           <FacebookIcon fontSize="large" />
                        </a>
                     </Grid>
                     <Grid item xs={4}>
                        <a
                           href="https://www.linkedin.com/company/lecosylcs"
                           style={{
                              color: "#fff",
                              textDecoration: "none",
                           }}
                           target={"_blank"}
                           rel="noreferrer noopener"
                           aria-label="LinkedIn"
                           alt={"LinkedIn"}
                        >
                           <LinkedInIcon fontSize="large" />
                        </a>
                     </Grid>
                     <Grid item xs={4} sx={{ mt: { xs: 0, md: 0.5 } }}>
                        <a
                           href="mailto:contacto@lecosy.com.mx?subject=SendMail&body=Description"
                           style={{
                              color: "#fff",
                              textDecoration: "none",
                           }}
                           target={"_blank"}
                           rel="noreferrer noopener"
                           aria-label="Email"
                           alt={"Email"}
                        >
                           <Box
                              sx={{
                                 py: 0.5,
                                 mx: 0.5,
                                 width: 27,
                                 bgcolor: "white",
                                 borderRadius: 1,
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 mt: { xs: 0.5, md: 0 },
                              }}
                           >
                              <EmailIcon color="primary" sx={{ fontSize: 20 }} />
                           </Box>
                        </a>
                     </Grid>
                     <Grid
                        item
                        xs={4}
                        sx={{
                           display: "flex",
                           alignContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <a
                           href="https://open.spotify.com/show/0pxdHri9UcOvdZ8A1b0Fe5?si=a44bf572796e4aa1&nd=1"
                           style={{
                              color: "#162c44",
                              textDecoration: "none",
                           }}
                           target={"_blank"}
                           rel="noreferrer noopener"
                           aria-label="Youtube"
                           alt={"Youtube"}
                        >
                           <Box
                              sx={{
                                 py: 0.5,
                                 mx: 0.5,
                                 width: 27,
                                 bgcolor: "white",
                                 borderRadius: 1,
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 mt: { xs: 0.5, md: 0 },
                                 mb: 1,
                              }}
                           >
                              <img
                                 width={20}
                                 src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/spotify-logo.svg"
                                 sx={{ bgcolor: "white" }}
                                 alt="spotify"
                              />
                           </Box>
                        </a>
                     </Grid>
                     <Grid item xs={4}>
                        <a
                           href="https://www.youtube.com/@lecosycsystem"
                           style={{
                              color: "#162c44",
                              textDecoration: "none",
                           }}
                           target={"_blank"}
                           rel="noreferrer noopener"
                           aria-label="Youtube"
                           alt={"Youtube"}
                        >
                           <Box
                              sx={{
                                 py: 0.5,
                                 mx: 0.5,
                                 width: 27,
                                 bgcolor: "white",
                                 borderRadius: 1,
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 mt: { xs: 0.5, md: 0 },
                                 mb: 1,
                              }}
                           >
                              <YouTubeIcon color="primary" sx={{ fontSize: 20 }} />
                           </Box>
                        </a>
                     </Grid>
                     <Grid item xs={4}>
                        <a
                           href="https://www.instagram.com/lecosylcs/"
                           style={{
                              color: "#162c44",
                              textDecoration: "none",
                           }}
                           target={"_blank"}
                           rel="noreferrer noopener"
                           aria-label="Instagram"
                           alt={"Instagram"}
                        >
                           <Box
                              sx={{
                                 py: 0.5,
                                 mx: 0.5,
                                 width: 27,
                                 bgcolor: "white",
                                 borderRadius: 1,
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 mt: { xs: 0.5, md: 0 },
                              }}
                           >
                              <InstagramIcon color="primary" sx={{ fontSize: 20 }} />
                           </Box>
                        </a>
                     </Grid>
                     <Grid
                        item
                        xs={4}
                        sx={{
                           display: "flex",
                           alignContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <a
                           href="https://www.tiktok.com/@lecosylcs"
                           style={{
                              color: "#162c44",
                              textDecoration: "none",
                           }}
                           target={"_blank"}
                           rel="noreferrer noopener"
                           aria-label="Tiktok"
                           alt={"Tiktok"}
                        >
                           <Box
                              sx={{
                                 py: 0.5,
                                 mx: 0.5,
                                 width: 27,
                                 bgcolor: "white",
                                 borderRadius: 1,
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 mt: { xs: 0.5, md: 0 },
                              }}
                           >
                              <img
                                 width={20}
                                 src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/tiktok-logo.svg"
                                 sx={{ bgcolor: "white" }}
                                 alt="tiktok"
                              />
                           </Box>
                        </a>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item xs={6} md={4} lg={4}>
                  <Box
                     component={"img"}
                     src={"https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy-blanco.png"}
                     alt="logo"
                     sx={{ height: { md: 80, sm: 65, xs: 55 }, paddingLeft: { xs: 1, md: 5 } }}
                  />
               </Grid>
               <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  sx={{
                     pl: { xs: 5, md: 5 },
                     pr: { xs: 5, md: 13 },
                     mt: { xs: 2, md: 0 },
                  }}
               >
                  <Box display={"flex"} sx={{ flexDirection: "row", alignItems: "center", mb: 0.5 }}>
                     <PlaceIcon sx={{ width: 22, mr: 0.8 }} />
                     <Typography sx={{ fontSize: "14px" }}>
                        José María Yañez 8, San Benito 83190, Hermosillo, Sonora
                     </Typography>
                  </Box>
                  <Box display={"flex"} sx={{ flexDirection: "row", alignItems: "center", mb: 0.5 }}>
                     <EmailIcon sx={{ width: 22, mr: 0.8 }} />
                     <Typography sx={{ fontSize: "14px" }}>contacto@lecosy.com.mx</Typography>
                  </Box>
                  <Box display={"flex"} sx={{ flexDirection: "row", alignItems: "center" }}>
                     <PhoneIcon sx={{ width: 22, mr: 0.8 }} />
                     <Typography sx={{ fontSize: "14px" }}>662 285 48 72</Typography>
                  </Box>
                  <Box display={"flex"} sx={{ flexDirection: "row", alignItems: "center", mb: 0.5 }}>
                     <FileOpenIcon sx={{ width: 22, mr: 0.8 }} />
                     <Link
                        href="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/T%C3%A9rminos+y+condiciones_Lecosy+2024.pdf"
                        underline="hover"
                        target="_blank"
                        sx={{ fontSize: "14px", color: "white" }}
                     >
                        Términos y Condiciones
                     </Link>
                  </Box>
               </Grid>
            </Grid>
            <Typography variant="body2" sx={{ textAlign: "center" }}>
               Consulte nuestro{" "}
               <a href="/Aviso-de-privacidad" target="_blank" style={{ color: "white" }}>
                  <strong>Aviso de privacidad</strong>
               </a>
               <strong> | </strong>
               Copyright &copy; 2023 Lecosy, S.A.P.I DE C.V. Todos los recursos reservados.
            </Typography>
         </Box>
      </Box>
   );
};
