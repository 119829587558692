import { Avatar, Box, Tooltip } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SmsIcon from "@mui/icons-material/Sms";

const ContactFloatingButton = () => {
   return (
      <Box>
         <Tooltip
            title={"Contáctenos"}
            placement="left"
            sx={{
               position: "fixed",
               right: 40,
               bottom: 180,
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
            }}
            componentsProps={{
               tooltip: {
                  sx: {
                     p: 1,
                     bgcolor: "rgb(255, 255, 255, 0.5)",
                     color: "#162c44",
                     fontFamily: "bold",
                     fontSize: "16px",
                  },
               },
            }}
         >
            <Box>
               <a href="https://wa.me/526623569751" target="_blank" rel="noreferrer noopener">
                  <Avatar
                     sx={{
                        bgcolor: "rgb(255,255,255, 0.5)",
                        filter: "drop-shadow(5px 0px 3px #26B164)",
                        "&:hover": {
                           cursor: "pointer",
                           background: "linear-gradient(to right top, #6BDC4A, #009A73)",
                           filter: "drop-shadow(0px 0px 0px #009A73)",
                        },
                     }}
                  >
                     <WhatsAppIcon />
                     <Box position={"absolute"} top={11} right={7}>
                        <WhatsAppIcon sx={{ filter: "blur(1px)", color: "#000", opacity: 0.1 }} />
                     </Box>
                  </Avatar>
               </a>
            </Box>
         </Tooltip>
         <Tooltip
            title={"Visite nuestro blog"}
            placement="left"
            sx={{
               position: "fixed",
               right: 40,
               bottom: 120,
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
            }}
            componentsProps={{
               tooltip: {
                  sx: {
                     p: 1,
                     bgcolor: "rgb(255, 255, 255, 0.5)",
                     color: "#162c44",
                     fontFamily: "bold",
                     fontSize: "16px",
                  },
               },
            }}
         >
            <Box>
               <a href="https://blog.lecosy.com.mx/" target="_blank" rel="noreferrer noopener">
                  <Avatar
                     sx={{
                        bgcolor: "rgb(255,255,255, 0.5)",
                        filter: "drop-shadow(5px 0px 3px #162c44)",
                        "&:hover": {
                           cursor: "pointer",
                           filter: "drop-shadow(0px 0px 0px #162c44)",
                           background: "linear-gradient(to right top, #162c44, #73808F)",
                        },
                     }}
                  >
                     <SmsIcon />
                     <Box position={"absolute"} top={11} right={7}>
                        <SmsIcon sx={{ filter: "blur(1px)", color: "#000", opacity: 0.1 }} />
                     </Box>
                  </Avatar>
               </a>
            </Box>
         </Tooltip>
      </Box>
   );
};
export default ContactFloatingButton;
