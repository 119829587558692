const benefitCardContent = [
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Iconos+BENEFICIOS_INTEGRACI%C3%93N+NORMATIVA.svg)",
      title: "INTEGRACIÓN NORMATIVA",
      description:
         "Integramos diversas normativas con nuestros módulos, haciendo una solución compleja para el debido control de las empresas.",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Iconos+BENEFICIOS_INDICADORES+CLAVE.svg)",
      title: "INDICADORES CLAVE",
      description:
         "Generamos indicadores con la información detallada y visualización de datos para evaluar el rendimiento del cumplimiento de manera eficaz.",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Iconos+BENEFICIOS_INTERFACES+AMIGABLES.svg)",
      title: "INTERFACES AMIGABLES",
      description:
         "Diseñadas con la usabilidad en mente, nuestras interfaces proporcionan una experiencia de usuario fluida, permitiendo una fácil navegación y comprensión de las funcionalidades del sistema.",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Iconos+BENEFICIOS_SEGURIDAD.svg)",
      title: "SEGURIDAD",
      description:
         "Nuestro sistema en la nube está respaldado por estrictos controles de acceso en todo momento, del cual cumplimos con la legislación vigente y con los pilares de seguridad de la información.",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Iconos+BENEFICIOS_PERSONIFICACI%C3%93N.svg)",
      title: "PERSONALIZACIÓN",
      description:
         "Adaptamos el sistema a la identidad corporativa de su empresa, facilitando la creación de reportes con su logo y colores.",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Iconos+BENEFICIOS_TOMA+DE+DECISIONES+EFICIENTES.svg)",
      title: "TOMA DE DECISIONES EFICIENTES",
      description:
         "Basadas en datos sólidos y análisis detallados, nuestras herramientas de toma de decisiones le brindan la confianza necesaria para liderar con solidez y dirección estratégica.",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Iconos+BENEFICIOS_TRAZABILIDAD.svg)",
      title: "TRAZABILIDAD",
      description: "Facilitamos el seguimiento y la revisión de acciones, asegurando un historial detallado.",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Iconos+BENEFICIOS_CULTURA+DE+CUMPLIMIENTO.svg)",
      title: "CULTURA DE CUMPLIMIENTO",
      description:
         "Fomentamos la ética y la integridad con funciones que refuerzan una cultura de cumplimiento en todos los niveles de la organización.",
   },
];

export default benefitCardContent;
