import { useState } from "react";
import { Box, Button, Stepper, StepConnector, StepLabel, Grid, Step } from "@mui/material";
import { ThemeConfig } from "../../../config/theme.config.js";
import ScrollToTop from "../../../wrapper/scrollToTop.jsx";
import { GovernanceFormHeader } from "./Components/GovernanceFormHeader.js";
import { useNavigate } from "react-router-dom";
import { respuestas } from "../../../utils/respuestas.js";
import StepOneForm from "./Components/StepOneForm.js";
import StepTwoForm from "./Components/StepTwoForm.js";
import { sendGoogleSheetData } from "../../../lib/lecosyBackend.js";
import { Country, State } from "country-state-city";
import { useEmail } from "../EmailContext.jsx";

export const GovernanceFormScreen = () => {
   const { email } = useEmail();
   const [activeStep, setActiveStep] = useState(0);
   const [formData, setFormData] = useState([
      { field: "email", value: email },
      { field: "nombre", value: "", categoria: "" },
      { field: "apellido", value: "", categoria: "" },
      { field: "pais", value: "", categoria: "" },
      { field: "entidadFederativa", value: "", categoria: "" },
      { field: "rolEmpresa", value: "", categoria: "" },
      { field: "sectorEmpresa", value: "", categoria: "" },
      { field: "tamanoEmpresa", value: "", categoria: "" },
      { field: "numeroEmpleados", value: "", categoria: "" },
      { field: "aniosMercado", value: "", categoria: "" },
      { field: "empresaFamiliar", value: "", categoria: "" },
      { field: "etapaEmpresa", value: "", categoria: "" },
      { field: "tipoSociedad", value: "", categoria: "" },
      { field: "decisionesColegiadas", value: "", categoria: "" },
      { field: "riesgosDefinidos", value: "", categoria: "" },
      { field: "inversionesExternas", value: "", categoria: "" },
      { field: "riesgosOperacionales", value: "", categoria: "" },
      { field: "problemasGestionInterna", value: "", categoria: "" },
      { field: "competenciaMercado", value: "", categoria: "" },
      { field: "planSucesion", value: "", categoria: "" },
      { field: "problemasComunicacion", value: "", categoria: "" },
      { field: "tecnologiasModernas", value: "", categoria: "" },
      { field: "problemasControlFinanciero", value: "", categoria: "" },
      { field: "principalesRetos", value: [], categoria: "" }, // Se inicializa como un array
   ]);

   const steps = ["Información general", "Acerca de la empresa"];
   const navigate = useNavigate();

   const handleNext = async () => {
      if (isStepComplete(activeStep)) {
         setActiveStep((prevStep) => prevStep + 1);
      } else {
         alert("Por favor, complete todas las preguntas antes de continuar.");
      }
   };

   const isStepComplete = (step) => {
      if (step === 0) {
         return [
            "nombre",
            "apellido",
            "pais",
            "rolEmpresa",
            "sectorEmpresa",
            "tamanoEmpresa",
            "numeroEmpleados",
            "aniosMercado",
            "empresaFamiliar",
            "etapaEmpresa",
            "tipoSociedad",
         ].every((field) => formData.find((item) => item.field === field)?.value);
      } else if (step === 1) {
         return [
            "decisionesColegiadas",
            "riesgosDefinidos",
            "inversionesExternas",
            "riesgosOperacionales",
            "problemasGestionInterna",
            "competenciaMercado",
            "planSucesion",
            "problemasComunicacion",
            "tecnologiasModernas",
            "problemasControlFinanciero",
            "principalesRetos",
         ].every((field) => formData.find((item) => item.field === field)?.value.length > 0);
      }
      return false;
   };

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => prevData.map((item) => (item.field === name ? { ...item, value: value } : item)));
      // document.body.scrollTop = 0; // For Safari
      // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
   };

   // Formatear los valores de país y entidad federativa para que sean legibles
   const formattedFormData = formData.map((item) => {
      if (item.field === "pais") {
         const country = Country.getCountryByCode(item.value);
         return {
            ...item,
            value: country ? country.name : item.value, // Reemplazar con el nombre completo del país
         };
      }
      if (item.field === "entidadFederativa") {
         const estado = State.getStateByCodeAndCountry(item.value, formData.find((f) => f.field === "pais")?.value);
         return {
            ...item,
            value: estado ? estado.name : item.value, // Reemplazar con el nombre completo de la entidad federativa
         };
      }
      return item;
   });

   const handleSubmit = async () => {
      const sheetUpdated = await sendGoogleSheetData(formattedFormData);
      if (!sheetUpdated) alert("Hubo un problema al guardar los datos en Google Sheets.");
      const updatedFormData = formData.map((item) => {
         //formData.push({ field: "email", value: email });
         const respuesta = respuestas.find((r) => r.field === item.field && r.value === item.value);

         return {
            ...item,
            categoria: respuesta ? respuesta.categoria : "",
         };
      });

      navigate("/diagnostico-gobernanza-empresarial/form/results", {
         state: { formData: updatedFormData },
      });
   };

   const isButtonDisabled = !isStepComplete(activeStep);
   const isLastStep = activeStep === steps.length - 1;
   const isFirstStep = activeStep === 0;

   return (
      <ThemeConfig>
         <ScrollToTop>
            <GovernanceFormHeader />
            <Box
               sx={{
                  width: "100%",
                  overflow: "hidden",
                  paddingX: { xs: 2, md: 4 },
                  paddingTop: { xs: 8, md: 12 },
                  paddingBottom: { xs: 3, md: 6 },
                  backgroundColor: "white",
               }}
            >
               <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  connector={<StepConnector sx={{ display: "none" }} />}
                  sx={{
                     ml: { sm: "0px", md: "88px" },
                     marginBottom: { xs: 1, md: 2 },
                     display: "flex",
                     flexWrap: "wrap",

                     //paddingX: { xs: 2, md: 10 },

                     width: { xs: "96%", sm: "97%", md: "60%" },
                     ".MuiStepLabel-labelContainer": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        fontWeight: "bold !important",
                        color: "#162C44",
                     },
                     ".MuiStepLabel-label": {
                        color: "#162C44",
                        fontWeight: "bold !important",
                        fontSize: { xs: "0.8rem", md: "1.1rem" },
                        lineHeight: "1",
                     },
                     ".MuiStep-root": {
                        fontWeight: "bold !important",
                        padding: 0,
                        flex: 1,
                        color: "#162C44",
                     },
                     ".MuiStepConnector-root": {
                        display: "none",
                     },
                  }}
               >
                  {steps.map((label, index) => (
                     <Step key={label}>
                        <StepLabel
                           StepIconComponent={() => null}
                           sx={{
                              backgroundColor: activeStep === index ? "#E8ECEF" : "#F8F9FA", //#F8F9FA E8ECEF
                              color: activeStep === index ? "#162C44" : "#8A95A0", // "#B0BEC5",
                              fontWeight: "bold !important",
                              //opacity: activeStep === index ? "1" : "0.3",
                              borderRadius: 3,

                              borderBottomLeftRadius: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              mb: 0,
                              fontSize: { xs: "1rem", md: "1.5rem" },
                              padding: { xs: "0px 0px 8px 0px", md: "0px 10px 10px 10px" },
                           }}
                        >
                           {label}
                        </StepLabel>
                     </Step>
                  ))}
               </Stepper>

               <form onSubmit={handleSubmit.ScrollT}>
                  <Box sx={{ paddingX: { xs: 2, md: 13 }, borderRadius: 2 }}>
                     <Grid
                        container
                        spacing={2}
                        sx={{
                           paddingX: { xs: 2, md: 10 },
                           paddingY: { xs: 3, md: 4 },
                           borderRadius: 3,
                           //borderTopRightRadius: { xs: 0, sm: 0, md: 10 },
                           backgroundColor: "#F8F9FA",
                        }}
                     >
                        {activeStep === 0 && <StepOneForm formData={formData} handleChange={handleChange} />}
                        {activeStep === 1 && <StepTwoForm formData={formData} handleChange={handleChange} />}
                     </Grid>
                  </Box>

                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 2,
                        paddingX: { xs: 2, md: 10 },
                     }}
                  >
                     <Button
                        variant="contained"
                        onClick={() => setActiveStep((prev) => prev - 1)}
                        disabled={isFirstStep}
                        sx={{
                           width: { xs: "45%", md: "auto" },
                        }}
                     >
                        Regresar
                     </Button>

                     <Button
                        variant="contained"
                        onClick={isLastStep ? handleSubmit : handleNext}
                        disabled={isButtonDisabled}
                        sx={{
                           width: { xs: "45%", md: "auto", lineHeight: 1.2 },
                        }}
                     >
                        {isLastStep ? "Conozca su resultado" : "Guardar"}
                     </Button>
                  </Box>
               </form>
            </Box>
         </ScrollToTop>
      </ThemeConfig>
   );
};
