import React from "react";
import { Box, Typography } from "@mui/material";

/**
 * SummaryCard component to display a title and summary text.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title text to be displayed.
 * @param {string} props.summary - The summary text to be displayed.
 * @returns {JSX.Element} The rendered summary card component.
 */
const SummaryCard = ({ title, summary }) => (
   <Box sx={{ mb: 3 }}>
      <Typography
         sx={{
            mb: 1,
            color: "#162C44",
            textAlign: "left",
            //textAlign: { sm: "left", md: "justify" },
            fontWeight: "bold", // Bold font weight
            fontSize: { xs: "24px", md: "32px" }, // Responsive font size
            lineHeight: 1.2,
         }}
      >
         {title}
      </Typography>
      <Typography
         sx={{
            color: "#162C44",
            textAlign: "justify",
            fontSize: { xs: "16px", md: "18px" }, // Responsive font size
            lineHeight: 1.4,
         }}
      >
         {summary}
      </Typography>
   </Box>
);

export default SummaryCard;
