import React from "react";
import { Box, Typography, Modal, Button, IconButton, ThemeProvider, createTheme , } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";


import Container from '@mui/material/Container';
import ReactPlayer from "react-player";

/**
 * Custom theme configuration for the modal and other MUI components.
 */
const theme = createTheme({
   palette: {
      primary: {
         main: "#162C44",
      },
      background: {
         default: "#E8ECEF",
      },
   },
   components: {
      MuiModal: {
         styleOverrides: {
            root: {
               border: "none !important",
               outline: "none !important",
               boxShadow: "none",
               "&:focus, &:active, &:hover, &:focus-visible": {
                  border: "none !important",
                  outline: "none !important",
               },
               "&::before, &::after": {
                  border: "none !important",
                  outline: "none !important",
               },
            },
         },
      },
      MuiButton: {
         styleOverrides: {
            root: {
               textTransform: "none",
            },
         },
      },
      MuiTextField: {
         styleOverrides: {
            root: {
               backgroundColor: "#FFFFFF",
               borderRadius: "12px",
               boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.4)",
               "& .MuiFilledInput-root": {
                  borderRadius: "12px",
                  boxShadow: "none",
                  "&:before, &:after, &:hover:not(.Mui-disabled):before, &.Mui-focused:after": {
                     borderBottom: "none !important",
                  },
               },
               "& .MuiInputBase-input": {
                  color: "#162C44",
               },
               "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
                  color: "#162C44",
               },
               "& .MuiInputBase-input::placeholder, & .Mui-focused .MuiInputBase-input::placeholder": {
                  color: "#162C44",
                  opacity: 1,
               },
            },
         },
      },
   },
});

/**
 * GovernanceTestModal component to display a modal with a custom theme,
 * including a close button, title, description, and a call-to-action button.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Boolean value to control the modal's open state.
 * @param {function} props.handleClose - Function to close the modal.
 * @returns {JSX.Element} The rendered modal component.
 */
export const GovernanceTestModal = ({ open, handleClose }) => {
   const navigate = useNavigate();
  
   const handleDiagnosticoClick = () => {
      navigate("/diagnostico-gobernanza-empresarial");
   };

   return (
      <ThemeProvider theme={theme}>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               "& .MuiBackdrop-root": {
                  backdropFilter: "blur(3px)",
                  border: "none",
                  outline: "none",
               },
            }}
         >
            <Box
               sx={{
                  width: { xs: "90%", sm: 600, md: 750 },
                  height: { xs: "auto" },
                  bgcolor: "background.paper",
                  border: "none",
                  outline: "none",
                  paddingX: { xs: 2, md: 10 },
                  paddingY: { xs: 5, md: 5},
                  /* backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/modal-bg.svg)", */
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: 2,
                  color: "#2D4357",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  lineHeight: "1"
               }}
            >
               <IconButton sx={{ position: "absolute", top: 8, right: 8 }} onClick={handleClose}>
                  <CloseIcon />
               </IconButton>
               <Typography id="modal-title" variant="h4" component="h2" 
               sx={{ 
                  mb: 2, textAlign: "center", lineHeight: "1", px:{xs:3, md:0},
                  fontSize:{xs: 22,md: 28, lg: 34}
                  }}>
                  <strong>Transforme su empresa </strong> con un gobierno corporativo eficaz.
               </Typography>
               <Container 
               sx={{display: "flex",justifyContent: "center", mb: {xs:1.5 ,md:1.5, lg:1.5},}}>

                  <ReactPlayer
                  className="player"
                     playing
                     url={"https://www.youtube.com/watch?v=otbzzcfdK5s"}
                     muted
                     controls
                     height={"305px"}
                     maxHeight={"305px"}
                     width={"100%"}
                     
                  />
                  
               </Container>
               


               <Typography id="modal-description" variant="h7" sx={{ px:{xs:3, md:0}, mb: 2, textAlign: "center", lineHeight: "1", 
               fontSize:{xs: 13.6, md: 16,
                  lg: 16},}}>
                  Realice nuestro <strong>diagnóstico de gobernanza</strong> y obtenga una{" "}
                  <strong style={{ color: "#E8606D" }}>sesión de consultoría  gratuita</strong> para fortalecer su
                  gobierno corporativo.
               </Typography>
               <Button
                  variant="contained"
                  onClick={handleDiagnosticoClick}
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     "& .MuiBackdrop-root": {
                        backdropFilter: "blur(3px)",
                        border: "none",
                        outline: "none",
                     },
                  }}
               >
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                     Acceder a diagnóstico
                  </Typography>
               </Button>
            </Box>
         </Modal>
      </ThemeProvider>
   );
};

export default GovernanceTestModal;
