import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Style } from "@mui/icons-material";

/**
 * InfoBox Component
 *
 * Displays an icon and a title inside a styled box, with a decorative background.
 *
 * @component
 * @param {string} iconRoute - The route of the icon to be displayed.
 * @param {JSX.Element} title - The title text to be displayed, with support for bold and regular text.
 * @returns {JSX.Element} The InfoBox component.
 */
const InfoBox = ({ iconRoute, title }) => (
   <Box
      sx={{
         position: "relative",
         flexGrow: 1, // Permite que el componente crezca según el espacio disponible
         width: "100%",
         maxWidth: { xs: "250px", sm: "350px" }, // Ancho máximo para mantener la escala
         textAlign: "center",

         mb: { xs: 4, sm: 6 }, // Ajuste de márgenes
         mx: "auto", // Centrar el componente
      }}
   >
      {/* Fondo decorativo */}
      <Box
         sx={{
            position: "absolute",
            top: 12,
            /* left: "50%",
            transform: "translateX(-50%)", */
            background:
               "linear-gradient(269.53deg, #8A95A0 5.43%, #5D6D7C 33.14%, #2D4357 54.07%, #162C44 74.41%, #000000 108.89%)",
            borderRadius: "40px",
            width: "100%",
            maxWidth: "250px",
            height: "100%",
            zIndex: 1,
         }}
      />
      {/* Caja principal */}
      <Box
         sx={{
            background: "linear-gradient(180deg, #E8ECEF -36.05%, #FFFFFF 84.68%)",
            border: "6px solid #E8ECEF",
            borderRadius: "40px",
            boxShadow: 3,
            paddingX: 2.5,
            paddingBottom: 2.8,
            minWidth: "200px",
            maxWidth: "250px",
            minHeight: "180px",
            display: "flex",
            flexDirection: "column", // Permitir que los elementos hijos crezcan
            justifyContent: "center", // Centrar el contenido verticalmente
            alignItems: "center", // Centrar el contenido horizontalmente
            position: "relative",
            zIndex: 2,
            mb: 10,
         }}
      >
         <Box
            sx={{
               position: "absolute",
               top: "-60px", // Ajustado para tamaño grande
               left: "50%",
               transform: "translateX(-50%)",
               borderRadius: "50%",
               width: "80px", // Tamaño grande
               height: "80px",
               background: "linear-gradient(135deg, #8A95A1 14.64%, #E3E3E3 85.36%)",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
            }}
         >
            <Box
               sx={{
                  width: "70px",
                  height: "70px",
                  background: "linear-gradient(180deg, #1D1D1B -26.19%, #162C44 87.99%)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
               }}
            >
               <Box
                  sx={{
                     position: "absolute",
                     width: "70%",
                     height: "70%",
                     backgroundImage: iconRoute,
                     backgroundSize: "contain",
                     backgroundPosition: "center",
                     backgroundRepeat: "no-repeat",
                     zIndex: 1,
                  }}
               />
            </Box>
         </Box>

         <Typography variant="body1" sx={{ mt: 4, color: "#2D4357" }}>
            {title}
         </Typography>
      </Box>
   </Box>
);

/**
 * GridExample Component
 *
 * Displays a grid of InfoBox components, each representing a different aspect of governance.
 */
const GridExample = () => (
   <Box
      sx={{
         /* mt: -5, */
         backgroundColor: "white",
         backgroundImage:
            "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/whatIsGovernanceBG.svg)",
         backgroundRepeat: "no-repeat",
         backgroundPosition: "center",
         backgroundSize: "100%",
         backgroundAttachment: "fixed",
         width: "100%",
         minHeight: "100vh",
         overflowX: "hidden",

         py: { xs: 5, md: 10 },
      }}
   >
      <Box
         sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            paddingBottom: { xs: 7, sm: 8, md: 10 },
         }}
      >
         <Typography
            sx={{
               fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
               color: "#2D4357",
               fontFamily: "regular",
            }}
         >
            ¿Qué es la <strong>gobernanza?</strong>
         </Typography>
      </Box>

      <Box
         sx={{
            position: "relative",
            maxWidth: "900px",
            mx: "auto",
            paddingTop: { xs: 5, md: 1 },
            px: { xs: 5, md: 5 },
         }}
      >
         <Grid
            container
            spacing={4}
            justifyContent="left"
            alignItems="stretch" // Se asegura de que todas las cajas se estiren para llenar el espacio disponible
            sx={{ marginBottom: { xs: 4, sm: 6, md: 10 }, position: "relative", ml: { sm: 0, md: "-10vw" } }}
         >
            <Grid item xs={12} sm={6} sx={{ position: "relative", textAlign: "center" }}>
               <InfoBox
                  iconRoute={
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/gestion.svg)"
                  }
                  title={
                     <Typography sx={{ lineHeight: 1.2, fontSize: "1.3rem" }}>
                        Dicta cómo debe <strong>gestionarse y controlarse</strong> una organización.
                     </Typography>
                  }
               />
               {/* Línea decorativa */}
               <Box
                  sx={{
                     position: "absolute",
                     top: { xs: "100%", sm: "50%" }, // Línea vertical para móviles
                     right: { xs: "50%", sm: "-50%" }, // Ajuste de la posición de la línea
                     transform: { xs: "rotate(90deg) translateY(-50%)", sm: "translateY(-50%)" }, // Rotación para la línea vertical
                     width: { xs: "2px", sm: "100%" }, // Línea vertical en móviles
                     height: { xs: "80px", sm: "2px" }, // Ajuste de altura para móviles
                     background: "linear-gradient(to right, #2D4357, #8A95A0)", // Degradado
                     zIndex: 0,
                  }}
               />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: "center", sm: "left" } }}>
               <InfoBox
                  iconRoute={
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/fact-check.svg)"
                  }
                  title={
                     <Typography sx={{ lineHeight: 1.2, fontSize: "1.3rem" }}>
                        Asegura la <strong>transparencia</strong>, la <strong>responsabilidad</strong> y la{" "}
                        <strong>equidad</strong> en la toma de decisiones.
                     </Typography>
                  }
               />
            </Grid>
         </Grid>

         <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="stretch"
            sx={{
               marginLeft: { sm: 0, md: 10 },
               position: "relative",
               ml: { sm: 0, md: "14vw" },
            }}
         >
            <Grid item xs={12} sm={6} sx={{ position: "relative", textAlign: { xs: "center", sm: "right" } }}>
               <InfoBox
                  iconRoute={
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/people.svg)"
                  }
                  title={
                     <Typography sx={{ lineHeight: 1.2, fontSize: "1.3rem" }}>
                        Implica la implementación de <strong>estructuras y mecanismos.</strong>
                     </Typography>
                  }
               />
               {/* Línea decorativa */}
               <Box
                  sx={{
                     position: "absolute",
                     top: { xs: "100%", sm: "50%" }, // Línea vertical para móviles
                     right: { xs: "50%", sm: "-50%" }, // Ajuste de la posición de la línea
                     transform: { xs: "rotate(90deg) translateY(-50%)", sm: "translateY(-50%)" }, // Rotación para la línea vertical
                     width: { xs: "2px", sm: "100%" }, // Línea vertical en móviles
                     height: { xs: "80px", sm: "2px" }, // Ajuste de altura para móviles
                     background: "linear-gradient(to right, #2D4357, #8A95A0)", // Degradado
                     zIndex: 0,
                  }}
               />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: "center", sm: "left" } }}>
               <InfoBox
                  iconRoute={
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/change.svg)"
                  }
                  title={
                     <Typography sx={{ lineHeight: 1.2, fontSize: "1.3rem" }}>
                        <strong> Adaptable</strong> a las características y necesidades <strong>específicas</strong> de
                        la organización.
                     </Typography>
                  }
               />
            </Grid>
         </Grid>
      </Box>
   </Box>
);

export default GridExample;
