import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { AnimationOnScroll } from "react-animation-on-scroll";

const ServiceImportanceSection = ({ title, description, phrase, additionalComponent }) => {
   return (
      <Stack
         direction={"column"}
         spacing={3}
         bgcolor={"#162c44"}
         px={{ xs: 3, lg: 10 }}
         py={{ lg: 5 }}
         color={"white"}
         sx={{
            backgroundImage:
               "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/background/Fondo-seccion-4.jpg)",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
         }}
      >
         <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent={"space-betweend"}
            pt={10}
            spacing={{ xs: 5, md: 0 }}
         >
            <Box flex={1} display={"flex"} justifyContent={{ sm: "center", md: "space-between" }} alignItems={"center"}>
               <Typography
                  fontFamily={"bold"}
                  fontSize={{ xs: "25px", md: "35px" }}
                  maxWidth={{ sm: "100%", md: "360px" }}
                  sx={{ lineHeight: 1.3 }}
               >
                  {title}
               </Typography>
            </Box>
            <Box flex={1}>{description}</Box>
         </Stack>
         <AnimationOnScroll animateIn="animate__zoomIn" animatePreScroll={false} animateOnce duration={0.75}>
            {phrase}
         </AnimationOnScroll>
         {additionalComponent}
      </Stack>
   );
};

ServiceImportanceSection.propTypes = {
   title: PropTypes.string.isRequired,
   description: PropTypes.element.isRequired,
   phrase: PropTypes.element.isRequired,
   additionalComponent: PropTypes.element,
};

export default ServiceImportanceSection;
