import { Typography } from "@mui/material";

export const DenounceChannelContent = [
   {
      question: "Clasificación y análisis de las denuncias, quejas y sugerencias",
      answer:
         "Nuestra plataforma permite analizar la información de primera entrada, la cual permite clasificar las denuncias, quejas y sugerencias de acuerdo a su categoría, asegurando que su gestión sea efectiva.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/videos/2.+Clasificaci%C3%B3n+y+an%C3%A1lisis+de+la+denuncia.mp4",
   },
   {
      question: "Proceso de investigación",
      answer: (
         <Typography>
            Con el objetivo de llegar a una solución y tomar decisiones de manera eficaz, es necesario llevar a cabo
            investigaciones y análisis exhaustivos para abordar cualquier preocupación ética o irregularidad.
            <br />
            <br />
            En este sentido, nuestra plataforma ofrece entrevistas diseñadas para agilizar el proceso de investigación,
            permitiendo así una resolución óptima en términos de eficiencia y eficacia.
         </Typography>
      ),
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/videos/3.+Proceso+de+investigaci%C3%B3n.mp4",
   },
   {
      question: "Proceso de resolución y cierre",
      answer:
         "La plataforma permite generar un expediente de toda la gestión de las denuncias, quejas y sugerencias, las cuales  contienen las resoluciones necesarias planteadas por el oficial de cumplimiento para la validación de la alta dirección.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/videos/4.+Proceso+de+resoluci%C3%B3n+y+cierre.mp4",
   },
];

export const GovernanceBodyContent = [
   {
      question: "2. Gestión eficiente de miembros del gobierno",
      answer:
         "Alta eficiencia al incorporar consejeros a través de perfiles individuales establecidos en nuestra plataforma, asegurando una gestión ágil y detallada.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/videos/2.+GESTI%C3%93N+EFICIENTE+DE+MIEMBROS+DEL+GOBIERNO.mp4",
   },
   {
      question: "3. Sesiones programadas",
      answer:
         "Programe todas las sesiones necesarias de los órganos de gobierno de manera sencilla, garantizando el cumplimiento de las reuniones.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/videos/3.+SESIONES+PROGRAMADAS.mp4",
   },
   {
      question: "4. Convocatorias y reuniones simplificadas",
      answer:
         "Reciba notificaciones y detalles de sesiones de forma eficiente con el fin de siempre estar preparado para las deliberaciones importantes de los órganos de gobierno.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/videos/4.+CONVOCATORIAS+Y+REUNIONES+SIMPLIFICADAS.mp4",
   },
   {
      question: "5. Minutas precisas y gestión documental eficaz",
      answer:
         "Realice un seguimiento detallado de acuerdos y decisiones tomadas durante las sesiones, complete minutas y gestione documentación relevante para un control óptimo.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/videos/5.+MINUTAS+PRECISAS+Y+GESTI%C3%93N+DOCUMENTAL+EFICAZ.mp4",
   },
];

export const BeneficialOwnerBodyContent = [
   {
      question: "Estructura documental eficiente",
      answer:
         "Genere y mantenga una estructura documental completa y organizada por cada beneficiario controlador, incluyendo información clave para generar su ficha correspondiente, así como los controles necesarios que la autoridad requiere.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/videos/2.+Estructura+documental+eficiente.mp4",
   },
   {
      question: "Gestión y mecanismos de actualización",
      answer:
         "Configure la periodicidad para actualización de información y documentos, garantizando que los datos se mantengan al día y en cumplimiento continuo con las normativas vigentes.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/videos/3.+Gesti%C3%B3n+y+mecanismos+de+actualizaci%C3%B3n.mp4",
   },
   {
      question: "Solicitud eficiente de información",
      answer:
         "Solicite y recolecte de manera eficiente la información requerida a cada uno de los beneficiarios controladores, minimizando el tiempo de respuesta y asegurando la precisión de los datos.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/videos/4.+Solicitud+eficiente+de+informaci%C3%B3n.mp4",
   },
];

export const GovernanceBodyExtraContent = [
   {
      title: "Comunicación directa y efectiva",
      description:
         "Facilitamos una comunicación fluida entre consejeros y miembros de la estructura de gobierno, asegurando una interacción directa y efectiva para una toma de decisiones ágil.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/videos/4.+Solicitud+eficiente+de+informaci%C3%B3n.mp4",
   },
   {
      title: "Registro detallado de acuerdos y notas",
      description:
         "Tome notas personales y grupales sobre acuerdos estratégicos, manteniendo un registro claro de las decisiones y compromisos tomados durante las sesiones de gobierno.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/videos/7.+REGISTRO+DETALLADO+DE+ACUERDOS+Y+NOTAS.mp4",
   },
   {
      title: "Actualizaciones constantes y tendencias relevantes",
      description:
         "Manténgase al día con las últimas tendencias e insights relevantes para una toma de decisiones informada y alineada con las estrategias de gobierno de su empresa.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/videos/8.+ACTUALIZACIONES+CONSTANTES+Y+TENDENCIAS+RELEVANTES.mp4",
   },
   {
      title: "Reportes de desempeño detallados",
      description:
         "Acceda a reportes periódicos de desempeño individual y de cada órgano de gobierno para evaluar el cumplimiento de objetivos y planificar mejoras estratégicas.",
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/videos/9.+REPORTES+DE+DESEMPE%C3%91O+DETALLADOS.mp4",
   },
];

export const ComplianceProgramContent = [
   {
      question: "Evaluación de cumplimientos",
      answer: (
         <Typography>
            Examine detalladamente las obligaciones de su empresa y tome medidas concretas para asegurar su
            cumplimiento.
            <br />
            <br />
            Nuestro módulo analiza de manera precisa aquellas obligaciones que requieren atención, permitiéndole
            implementar acciones necesarias para garantizar un cumplimiento efectivo.
         </Typography>
      ),
   },
   {
      question: "Mecanismos de cumplimiento",
      answer:
         "Explore los mecanismos para cumplir con cada una de las obligaciones que afectan a su empresa mediante asesorías proporcionadas por expertos y el acceso a las herramientas esenciales para eficientar su cumplimiento.",
   },
   {
      question: "Gestión de obligaciones",
      answer:
         "Acceda a una guía de cumplimiento que le brinde una visión integral de todas las obligaciones de su organización, permitiéndole comprender y abordar proactivamente sus responsabilidades en la prevención de riesgos.",
   },
   {
      question: "Orden documental",
      answer: (
         <Typography>
            Organice y gestione de manera ordenada, eficiente y accesible los documentos que respalden el cumplimiento
            de sus obligaciones.
            <br />
            <br />
            Nuestro módulo reconoce que la estructura documental es crucial para la toma de decisiones fundamentadas en
            evidencias sólidas.
         </Typography>
      ),
   },
   {
      question: "Reportes de cumplimiento",
      answer: (
         <Typography>
            Nuestra plataforma le permite generar informes detallados para la rendición de cuentas, ya sea desde una
            perspectiva general de cumplimiento empresarial o desglosados por materias y factores específicos.
            <br />
            <br />
            Asegure una transparencia total en la evaluación de sus actividades y logre una gestión informada de sus
            obligaciones.
         </Typography>
      ),
   },
];
