import { Box, Typography } from "@mui/material";
import { NavBar, WriteUs, Footer } from "../components";
import ScrollToTop from "../wrapper/scrollToTop";
import { ComplaintPlatformButton } from "../components/ComplaintPlatformButton";
import { AnimationOnScroll } from "react-animation-on-scroll";

const contactInfo = (title, description) => {
   return (
      <Box sx={{ display: "flex", flexDirection: "column", mb: 5 }}>
         <Typography
            color={"primary"}
            sx={{ fontFamily: "Bold", textAlign: { xs: "center", md: "left" }, fontSize: { xs: "20px", md: "22px" } }}
         >
            {title}
         </Typography>
         <Typography
            color={"primary"}
            sx={{ textAlign: { xs: "center", md: "left" }, fontSize: { xs: "18px", md: "20px" } }}
         >
            {description}
         </Typography>
      </Box>
   );
};
export const ContactScreen = () => {
   return (
      <ScrollToTop>
         <Box
            sx={{
               backgroundImage:
                  "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/contacto/Banner.png)",
               backgroundSize: { xs: "contain", md: "cover" },
               backgroundRepeat: "no-repeat",
               backgroundPosition: "top center",
            }}
         >
            <NavBar />
            <Box sx={{ px: { xs: 4, sm: 10, md: 10 } }}>
               <Typography
                  fontFamily={"bold"}
                  color={"primary"}
                  sx={{
                     textAlign: "center",
                     fontSize: "30px",
                     pt: { xs: "40px", md: "100px" },
                     pb: { xs: "20px", md: "20px" },
                     lineHeight: 1.2,
                  }}
               >
                  Quiero más información y una demostración
               </Typography>
               <Typography fontFamily={"bold"} py={{ xs: 1, lg: 3 }}>
                  Complete la siguiente información, y en unos minutos uno de nuestros expertos se pondrá en contacto
               </Typography>
            </Box>
            <Box sx={{ py: 5, px: { xs: 2, sm: 5, md: 10 }, mx: { xs: 2, sm: 5, md: 0 } }}>
               <WriteUs />
            </Box>
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
               }}
            >
               <Box
                  sx={{
                     py: 5,
                     display: "flex",
                     flexDirection: { xs: "column", md: "row" },
                     justifyContent: { md: "space-around", xs: "center" },
                     alignItems: { xs: "center", md: "auto" },
                  }}
               >
                  <Box
                     sx={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        px: { xs: 3, md: 10 },
                     }}
                  >
                     <AnimationOnScroll
                        animateIn="animate__slideInLeft"
                        animatePreScroll={false}
                        animateOnce
                        duration={0.75}
                     >
                        {contactInfo("Llámenos", "(662)285 48 72")}
                        {contactInfo("Envíenos correo", "contacto@lecosy.com.mx")}
                        {contactInfo("Ubíquenos", "José María Yañez 8, San Benito 83190, Hermosillo, Sonora")}
                     </AnimationOnScroll>
                  </Box>
                  <Box
                     sx={{
                        width: { xs: "100%", md: "auto" },
                        flex: 1,
                        backgroundColor: "rgba(217, 217, 217,0.5)",
                        borderTopLeftRadius: { md: "8px" },
                        borderBottomLeftRadius: { md: "8px" },
                        justifyContent: { md: "flex-start", xs: "center" },
                        py: { md: 2 },
                        px: { md: 10 },
                     }}
                  >
                     <a
                        href="https://www.google.com.mx/maps/place/Lecosy+Legal+Compliance/@29.0908762,-110.9605785,17z/data=!3m1!4b1!4m6!3m5!1s0x86ce854194fd1287:0x410063917661cd89!8m2!3d29.0908762!4d-110.9580036!16s%2Fg%2F11txpfy_8y"
                        rel="noreferrer"
                        target="_blank"
                        style={{ color: "white" }}
                     >
                        <Box
                           sx={{
                              borderRadius: { md: "10px" },
                              backgroundImage:
                                 "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Lecosy-map.png)",
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                              height: "482px",
                              width: { xs: "100%", md: "633px" },
                              ml: { md: -8 },
                           }}
                        />
                     </a>
                  </Box>
               </Box>
            </Box>
         </Box>
         <ComplaintPlatformButton />
         <Footer />
      </ScrollToTop>
   );
};
