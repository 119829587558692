import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const StyledAccordion = ({
   question,
   answer,
   selectedAnswer,
   setSelectedAnswer,
   setSelectedVideo,
   videoUrl,
}) => {
   const handleClick = () => {
      setSelectedVideo(videoUrl);
      setSelectedAnswer(answer);
   };

   return (
      <Stack spacing={0.2}>
         <Button
            className={selectedAnswer === answer ? "gradientBox904" : undefined}
            onClick={handleClick}
            onTouchStart={handleClick}
            style={{
               backgroundColor: "#E8ECEF",
               padding: "8px 0",
               borderTopLeftRadius: 0,
               borderBottomLeftRadius: 0,
               borderTopRightRadius: 5,
               borderBottomRightRadius: 5,
               width: "100%",
               textTransform: "none",
            }}
         >
            <Stack spacing={4} direction={"row"} width={"100%"} justifyContent={"space-between"} px={3}>
               <Typography
                  fontFamily={"ExtraBold"}
                  pl={8}
                  fontSize={selectedAnswer === answer ? "20px" : "16px"}
                  color={"#162c44"}
                  textAlign={"left"}
               >
                  {question}
               </Typography>
               <ChevronRightIcon
                  sx={{
                     alignSelf: "center",
                     display: selectedAnswer === answer ? "none" : "block",
                     color: "#162c44",
                  }}
               />
            </Stack>
         </Button>
         <Divider
            sx={{
               bgcolor: "#162c44",
               width: { xs: "99%", lg: "99%" },
               borderBottomWidth: "3px",

               display: selectedAnswer === answer ? "block" : "none",
            }}
         />
      </Stack>
   );
};

export const StyledAccordionComponent = ({
   title,
   mainTitleContent,
   mainVideoContent,
   content,
   selectedAnswer,
   setSelectedAnswer,
   setSelectedVideo,
}) => {
   const handleClick = () => {
      setSelectedVideo(mainVideoContent);
      setSelectedAnswer(mainTitleContent);
   };
   return (
      <Stack direction={"column"} maxWidth={{ xs: "99%", lg: "70%" }}>
         <Button
            onClick={() => {
               handleClick();
            }}
            onTouchStart={() => {
               handleClick();
            }}
            className={selectedAnswer.key === "mainTitleContent" ? "gradientBox904" : undefined}
            sx={{
               borderTopRightRadius: 8,
               borderBottomRightRadius: 8,
               "&:hover": { cursor: "pointer" },
            }}
            display={"flex"}
            justifyContent={"space-between"}
            px={3}
            bgcolor={"#E8ECEF"}
            style={{
               backgroundColor: "#E8ECEF",
               padding: "2px 25px",

               borderTopLeftRadius: 0,
               borderBottomLeftRadius: 0,
               borderTopRightRadius: 5,
               borderBottomRightRadius: 5,
               width: "100%",
               textTransform: "none",
               justifyContent: "space-between",
            }}
         >
            <Typography
               fontFamily={"ExtraBold"}
               py={1}
               pl={8}
               fontSize={selectedAnswer.key === "mainTitleContent" ? "20px" : "16px"}
               color={"#162c44"}
               textAlign={"left"}
            >
               {title}
            </Typography>
            <ChevronRightIcon
               sx={{
                  alignSelf: "center",
                  display: selectedAnswer.key === "mainTitleContent" ? "none" : "block",
                  color: "#162c44",
               }}
            />
         </Button>
         <Divider
            sx={{
               bgcolor: "#162c44",
               width: { xs: "99%", lg: "99%" },
               borderBottomWidth: "3px",

               display: selectedAnswer.key === "mainTitleContent" ? "block" : "none",
            }}
         />
         <Stack direction={"column"} py={0.75} spacing={0.75}>
            {content.map((e) => {
               return (
                  <StyledAccordion
                     key={e.question}
                     {...e}
                     selectedAnswer={selectedAnswer}
                     setSelectedAnswer={setSelectedAnswer}
                     setSelectedVideo={setSelectedVideo}
                  />
               );
            })}
         </Stack>
      </Stack>
   );
};

StyledAccordionComponent.propTypes = {
   title: PropTypes.string.isRequired,
   content: PropTypes.object.isRequired,
   selectedAnswer: PropTypes.string,
   setSelectedAnswer: PropTypes.func,
   setSelectedVideo: PropTypes.func,
   mainTitleContent: PropTypes.string,
   mainVideoContent: PropTypes.string,
};

StyledAccordion.propTypes = {
   question: PropTypes.string.isRequired,
   answer: PropTypes.string.isRequired,
   selectedAnswer: PropTypes.string,
   setSelectedAnswer: PropTypes.func,
   setSelectedVideo: PropTypes.func,
   videoUrl: PropTypes.string,
};
