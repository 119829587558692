import { Box, Button, Divider, Grid, Slide, Typography } from "@mui/material";
import { NavBar, Footer } from "../components";
import { ThemeConfig } from "../config/theme.config";
import { ScrollToTopButton } from "../components/scrollToTopButton";
import FutureModules from "../const/futureModules";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ScrollToTop from "../wrapper/scrollToTop";
import { useCallback, useEffect, useState } from "react";

export const descriptionZone = (sysmodules, i) => {
   return (
      <Box
         sx={{
            flexDirection: "column",
            flex: 1,

            px: { xs: 1, sm: 6, md: 0 },
            py: { xs: 1, sm: 3, md: 0 },
            //alignItems: "center",
         }}
      >
         <Grid container direction={i % 2 === 0 ? "row" : "row-reverse"} spacing={{ lg: 1 }}>
            <Grid item xs={1.5}>
               <AnimationOnScroll
                  animateIn="animate__bounceIn"
                  animateOnce
                  animatePreScroll={false}
                  duration={0.75}
                  delay={750}
               >
                  <Box
                     width={"100%"}
                     //height={"65px"}
                     //mb={{ xs: -6, sm: -4, md: -3 }}
                     sx={{
                        backgroundImage: sysmodules.icon,
                        backgroundRepeat: "no-repeat",
                     }}
                  />
               </AnimationOnScroll>
            </Grid>
            <Grid item xs={12}>
               <Box sx={{ px: { sm: 2, md: 4, lg: 10 } }}>
                  <Typography
                     color={"primary"}
                     sx={{
                        fontFamily: "ExtraBold",
                        fontSize: "1.5rem",
                        textAlign: { xs: "center", md: "left" },
                        letterSpacing: 0.5,
                        lineHeight: 1.2,
                        //mt: { lg: "-60px" },
                        //px: { xs: 6 },
                     }}
                  >
                     {sysmodules.title}
                  </Typography>
                  <AnimationOnScroll
                     animateIn="animate__slideInLeft"
                     animateOnce
                     animatePreScroll={false}
                     duration={0.75}
                  >
                     <Divider sx={{ borderBottomWidth: "6px", bgcolor: "#162c44", my: 1 }} />
                     {sysmodules.description}
                     {sysmodules.list}
                  </AnimationOnScroll>
               </Box>
            </Grid>
         </Grid>
      </Box>
   );
};
export const descriptionZoneProx = (sysmodules, i) => {
   return (
      <Box
         sx={{
            flexDirection: "column",
            flex: 1,

            px: { xs: 1, sm: 6, md: 0 },
            py: { xs: 1, sm: 3, md: 0 },
            //alignItems: "center",
         }}
      >
         <Grid container direction={i % 2 === 0 ? "row" : "row-reverse"} spacing={{ lg: 1 }}>
            <Grid item xs={1.5}>
               <AnimationOnScroll
                  animateIn="animate__bounceIn"
                  animateOnce
                  animatePreScroll={false}
                  duration={0.75}
                  delay={750}
               >
                  <Box
                     width={"100%"}
                     height={"65px"}
                     mb={{ xs: -6, sm: -4, md: -3 }}
                     sx={{
                        backgroundImage: sysmodules.icon,
                        backgroundRepeat: "no-repeat",
                     }}
                  />
               </AnimationOnScroll>
            </Grid>
            <Grid item xs={12}>
               <Box sx={{ px: { sm: 2, md: 4, lg: 10 } }}>
                  <Typography
                     color={"primary"}
                     sx={{
                        fontFamily: "ExtraBold",
                        fontSize: "1.5rem",
                        textAlign: { xs: "center", md: "left" },
                        letterSpacing: 0.5,
                        lineHeight: 1.2,
                        mt: { md: "-30px", lg: "-60px" },
                        px: { xs: 6 },
                     }}
                  >
                     {sysmodules.title}
                  </Typography>
                  <AnimationOnScroll
                     animateIn="animate__slideInLeft"
                     animateOnce
                     animatePreScroll={false}
                     duration={0.75}
                  >
                     <Divider sx={{ borderBottomWidth: "6px", bgcolor: "#162c44", my: 1, mx: { md: 6, lg: 0 } }} />
                     {sysmodules.description}
                     {sysmodules.list}
                  </AnimationOnScroll>
               </Box>
            </Grid>
         </Grid>
      </Box>
   );
};
export const imageZone = (sysmodules, i) => {
   return (
      <Box
         sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            px: { xs: 0, sm: 0, lg: 0 },
            pt: { sm: 1 },
         }}
      >
         <Box
            sx={{
               backgroundImage: `url(${sysmodules.image})`,
               backgroundSize: "cover",
               backgroundPosition: "center center",
               height: "300px",
               width: { xs: "100%", sm: "93%", md: "85%", lg: "90%" },
               //transform: { lg: i ? `translate(${positionX}%, 0)` : `translate(${positionX * -1}%, 0)` },
            }}
         />
      </Box>
   );
};

export const ServiceScreen = () => {
   const [selectedService, setSelectedService] = useState("");
   const scrollToElement = useCallback(() => {
      if (selectedService.length === 0) return;
      const element = document.getElementById(selectedService.substring(1, selectedService.length - 1));
      if (element) {
         element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
         localStorage.removeItem("selectedService");
      }
   }, [selectedService]);

   useEffect(() => {
      const item = JSON.stringify(localStorage.getItem("selectedService"));
      if (item) {
         setSelectedService(item);
         return scrollToElement();
      }
   }, [selectedService, scrollToElement]);

   return (
      <ThemeConfig>
         <ScrollToTop>
            <Box
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/proximo-servicio/Banner.png)",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  width: "100%",
                  height: "105vh",
                  py: { lg: 10 },
               }}
            >
               <NavBar />
               <Grid container sx={{ display: "flex", justifyContent: "center", py: { xs: 10, md: 10 } }}>
                  <Grid item xs={12} md={10}>
                     <Slide direction={"right"} in={true} appear timeout={500}>
                        <Typography
                           color={"white"}
                           fontFamily={"bold"}
                           textAlign="center"
                           sx={{
                              lineHeight: 1.1,
                              fontSize: { xs: "50px", sm: "40px", md: "50px" },
                              textShadow: "0px 2px 2px  #081524",
                              alignItems: "center",
                              justifyContent: "center",
                              width: { lg: "80%" },
                              pl: { lg: "20%" },
                              pt: { xs: "50px", sm: "150px", md: "100px", lg: "0" },
                              px: { xs: "5%", sm: "5%" },
                           }}
                        >
                           PRÓXIMAS{" "}
                           <Typography
                              display={"inline"}
                              sx={{ lineHeight: 1.1, fontSize: { xs: "50px", sm: "40px", md: "50px" } }}
                              fontFamily={"ExtraBold"}
                           >
                              SOLUCIONES Lecosy
                           </Typography>
                        </Typography>
                     </Slide>
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     justifyContent={{ xs: "center", md: "flex-end" }}
                     display={"flex"}
                     px={{ md: 25 }}
                     pt={5}
                  >
                     <Button
                        href="https://calendar.app.google/KcrgoEnbz9SJScGv8"
                        target={"_blank"}
                        sx={{ borderRadius: 3, p: 1, bgcolor: "#FFFFFF", "&:hover": { bgcolor: "#8A95A1" } }}
                     >
                        <Box sx={{ display: "flex", alignItems: "center", px: 1 }} color={"#162c44"}>
                           <Typography fontFamily={"bold"}>Contactar a un consultor</Typography>
                           <ArrowForwardIcon fontSize={"small"} sx={{ ml: 0.5 }} />
                        </Box>
                     </Button>
                  </Grid>
               </Grid>
            </Box>
            <Box
               sx={{
                  direction: "column",
                  spacing: 3,
                  p: { xs: 3, lg: 10 },
                  //py: { md: 8 },
                  mb: { xs: 4, md: 0 },
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Fondo+beneficios.png)",
                  backgroundPosition: "center top",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
               }}
            >
               {FutureModules.map((sysModule, i) => {
                  return (
                     <Box
                        id={`section${i + 1}`}
                        className={`section${i + 1}`}
                        key={sysModule.title}
                        sx={{
                           display: "flex",
                           flexDirection: {
                              xs: "column",
                              md: i % 2 === 0 ? "row" : "row-reverse",
                           },
                           alignItems: { md: "center" },
                           py: 1,
                           px: 4,
                        }}
                     >
                        {descriptionZoneProx(sysModule, i)}
                        {imageZone(sysModule)}
                     </Box>
                  );
               })}
            </Box>
            {ScrollToTopButton(window)}
            <Footer />
         </ScrollToTop>
      </ThemeConfig>
   );
};
