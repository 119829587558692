import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

const WhatIsServiceComponent = ({ what, why, objective, hasObjective = false, imageUrl, fullHeight = false }) => {
   return (
      <Stack
         zIndex={10}
         direction={{ xs: "column-reverse", lg: hasObjective ? "row-reverse" : "row" }}
         sx={{ bgcolor: "#748190", display: "flex", minHeight: "600px" }}
      >
         <Stack direction={"column"} flex={1}>
            {hasObjective ? undefined : (
               <Box
                  width={{ md: "20%", lg: "20%" }}
                  height={{ sm: "60px", md: "200px", lg: "600px" }}
                  position={"absolute"}
                  right={0}
                  sx={{
                     backgroundImage:
                        "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/Fondo-Seccion-3-derecho.png)",
                     backgroundRepeat: "no-repeat",
                     backgroundSize: { lg: "350px 350px" },
                     backgroundPosition: "bottom right",
                     overflowX: "hidden",

                     opacity: 0.15,
                  }}
               />
            )}
            <Box
               mt={{ sm: 8, md: 5.8, lg: "auto" }}
               sx={{
                  width: "100%",
                  height: fullHeight ? "100%" : { xs: "400px", lg: "400px" },
                  backgroundImage: imageUrl,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                  overflowX: "hidden",
               }}
            />
            {hasObjective ? (
               <Box flex={1} py={5} px={{ xs: 6, lg: 8 }} bgcolor={"#46566A"}>
                  <Typography
                     fontFamily={"bold"}
                     color={"white"}
                     fontSize={"40px"}
                     flex={1}
                     sx={{
                        py: 2,
                        px: 3,
                        fontSize: { xs: "30px", md: "40px" },
                        textAlign: { xs: "center", lg: "left" },
                     }}
                  >
                     ¿Para qué es?
                  </Typography>
                  <Typography color={"white"} textAlign={"justify"} flex={1}>
                     {objective}
                  </Typography>
               </Box>
            ) : undefined}
         </Stack>
         <Box flex={1}>
            <Box
               height={"100%"}
               px={{ xs: 3, lg: 10 }}
               display={"flex"}
               flexDirection={"column"}
               justifyContent={"center"}
            >
               {!hasObjective ? undefined : (
                  <Box
                     width={{ xs: "100%", sm: "100%", md: "50%" }}
                     height={{ xs: "580px", sm: "88vh", md: "490px", lg: "740px" }}
                     position={"absolute"}
                     right={{ sm: "0", md: "0", lg: "50%" }}
                     sx={{
                        backgroundImage:
                           "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/Fondo-Seccion-3-derecho.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: { xs: "200px 200px", sm: "300px 300px", md: "300px 300px", lg: "350px 350px" },
                        backgroundPosition: "bottom right",
                        opacity: 0.15,
                        overflowX: "hidden",
                     }}
                  />
               )}

               <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  px={{ xs: 3, lg: 1 }}
                  py={{ lg: 1 }}
                  minHeight={{ xs: "580px", sm: "400px", md: "400px", lg: "720px" }}
               >
                  <Typography
                     fontFamily={"bold"}
                     color={"white"}
                     sx={{
                        py: 2,
                        px: 1,
                        fontSize: { xs: "30px", md: "40px" },
                        textAlign: { xs: "center", lg: "left" },
                     }}
                  >
                     ¿Qué es?
                  </Typography>
                  <Typography color={"white"} textAlign={"justify"} p={{ sm: 2, lg: 0 }}>
                     {what}
                  </Typography>
                  {!hasObjective ? (
                     <div>
                        <Typography
                           fontFamily={"bold"}
                           color={"white"}
                           sx={{
                              py: 2,
                              mt: 2,
                              fontSize: { xs: "30px", md: "40px" },
                              textAlign: { xs: "center", md: "left" },
                           }}
                        >
                           ¿Por qué contar con él?
                        </Typography>
                        <Typography color={"white"} textAlign={"justify"}>
                           {why}
                        </Typography>
                     </div>
                  ) : undefined}
               </Box>
            </Box>
         </Box>
      </Stack>
   );
};

WhatIsServiceComponent.propTypes = {
   what: PropTypes.string.isRequired,
   why: PropTypes.string,
   objective: PropTypes.string,
   hasObjective: PropTypes.bool,
   imageUrl: PropTypes.string,
   fullHeight: PropTypes.bool,
};

export default WhatIsServiceComponent;
