import axios from "axios";

const host = "https://server.lecosy.com.mx/cd";
const host2 = window.location.hostname === "localhost" ? "http://localhost:8002" : "https://server.lecosy.com.mx";

const handleError = (message) => {
   alert(message);
   return false;
};

export const sendMailToConsultas = async (name, lastName, position, phone, email, body, company, ecAct) => {
   const payload = { name, lastName, position, phone, email, body, company, ecAct };
   axios.defaults.withCredentials = true;
   return axios.post(`${host}/api/client/mail/send/consultas`, payload);
};

export const sendMailRequest = async ({ email, formData, name }) => {
   try {
      axios.defaults.withCredentials = true;

      // Filtrar duplicados de 'email' en formData antes de enviar
      const uniqueFormData = formData.filter(
         (item, index, self) => item.field !== "email" || index === self.findIndex((t) => t.field === "email")
      );

      const response = await axios.post(`${host2}/api/client/mail/send/send-governance-pdf`, {
         email,
         formData: uniqueFormData,
         name,
      });
      if ([200, 202].includes(response.status)) return true;

      return handleError("There was a problem sending the email.");
   } catch (error) {
      return handleError("There was a problem sending the email. Please try again later.");
   }
};

export const sendGoogleSheetData = async (formData) => {
   try {
      axios.defaults.withCredentials = true;

      // Filtrar duplicados de 'email' en formData
      const uniqueFormData = formData.filter(
         (item, index, self) => item.field !== "email" || index === self.findIndex((t) => t.field === "email")
      );

      const response = await axios.post(`${host2}/api/client/google/save-test-data`, { formData: uniqueFormData });
      if ([200, 201].includes(response.status)) return true;

      return handleError("There was a problem saving data to Google Sheets.");
   } catch (error) {
      return handleError("There was a problem saving data to Google Sheets. Please try again later.");
   }
};

export const sendWhatsAppRequest = async ({ phoneNumber, formData, name }) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.post(`${host2}/api/client/send-governance-whatsapp`, {
         phoneNumber,
         formData,
         name,
      });
      return response.data;
   } catch (error) {
      console.error("Error in sendWhatsAppRequest:", error);
      throw error;
   }
};
//prueba
