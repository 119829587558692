import { Card, Box, Typography, Stack } from "@mui/material";
import benefitCardContent from "../const/benefitsCardContent.js";
import { useRef } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

function primeNumber(number) {
   if (number < 4) return false;
   for (let i = 2; i < number; i++) if (number % i === 0) return false;
   return true;
}
export const BenefitCards = () => {
   const benefitSectionRef = useRef(null);

   const benefitCard = (e, i) => {
      const { icon, title, description } = e;
      return (
         <AnimationOnScroll animateIn="animate__fadeIn" duration={0.75} animateOnce animatePreScroll={false}>
            <Card
               key={e.title}
               elevation={5}
               sx={{
                  m: 2,
                  p: 2,
                  borderRadius: 2,
                  minHeight: "250px",
                  minWidth: { xs: "75vw", md: "30vw", lg: "20vw" },
                  maxWidth: { xs: "75vw", md: "30vw", lg: "20vw" },

                  bgcolor: (i % 2 === 0 && i <= 3) || primeNumber(i) ? "white" : "#E8ECEF",
               }}
            >
               <Stack direction={"column"} spacing={1}>
                  <Stack
                     direction={"row"}
                     spacing={0.5}
                     sx={{ borderBottom: 3, borderColor: "rgb(135, 146, 158, 0.4)" }}
                  >
                     <Box
                        sx={{
                           width: "50px",
                           height: "50px",
                           backgroundImage: icon,
                           backgroundRepeat: "no-repeat",
                           backgroundPosition: "center center",
                        }}
                     />
                     <Typography
                        fontFamily={"ExtraBold"}
                        color={"#162c44"}
                        textAlign={"left"}
                        fontSize={"18px"}
                        lineHeight={1.2}
                        alignSelf={"center"}
                     >
                        {title}
                     </Typography>
                  </Stack>
                  <Typography color={"#5D6D7C"}>{description}</Typography>
               </Stack>
            </Card>
         </AnimationOnScroll>
      );
   };

   return (
      <Box
         sx={{
            backgroundImage:
               "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/background/fondo-izquierdo-seccion-7.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "30%",
            backgroundPosition: "top left",
         }}
      >
         <Box
            ref={benefitSectionRef}
            sx={{
               px: {
                  xs: 3,
                  md: 10,
               },
               backgroundImage:
                  "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/background/fondo-derecho-seccion-7.png)",
               backgroundRepeat: "no-repeat",
               backgroundSize: "30%",
               backgroundPosition: "top right",
            }}
            py={5}
         >
            <Box>
               <Typography
                  textAlign={"center"}
                  color={"primary"}
                  fontFamily={"bold"}
                  sx={{ fontSize: { xs: "30px", md: "35px" } }}
               >
                  Cualidades de nuestro sistema
               </Typography>
               <AnimationOnScroll animateIn="animate__slideInLeft" animatePreScroll={false} duration={1} animateOnce>
                  <Box display={"flex"} justifyContent={"center"} py={2}>
                     <Typography
                        textAlign={"center"}
                        sx={{ borderBottom: 6, borderColor: "#162c44", fontSize: { xs: "15px", md: "20px" } }}
                     >
                        Descubra por qué Lecosy es la mejor opción para su organización.
                     </Typography>
                  </Box>
               </AnimationOnScroll>
            </Box>
            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-around"} mt={2} textAlign={"justify"}>
               {benefitCardContent.map((e, i) => {
                  return benefitCard(e, i);
               })}
            </Box>
         </Box>
      </Box>
   );
};
