import { Box, Card, Stack, Typography } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import React from "react";
import PropTypes from "prop-types";
import { AnimationOnScroll } from "react-animation-on-scroll";

const ModuleCard = ({ icon, title, description, link, avaliable = false }) => {
   return (
      <Card py={2} sx={{ width: "320px", boxShadow: 5, borderRadius: "10px", my: 3 }}>
         <Stack direction={"column"} spacing={2} alignItems={"center"} py={3}>
            <AnimationOnScroll
               animateIn="animate__bounceIn"
               animateOnce
               animatePreScroll={false}
               duration={0.75}
               delay={50}
            >
               <Box
                  sx={{
                     width: "150px",
                     height: "125px",
                     backgroundImage: icon,
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "center center",
                  }}
               />
            </AnimationOnScroll>
            <Typography fontFamily={"Extrabold"} fontSize={"18px"}>
               {title}
            </Typography>
            <Typography
               px={3}
               textAlign={"justify"}
               color={"#5D6D7C"}
               height={"100px"}
               overflow={"hidden"}
               lineHeight={1.3}
            >
               {description}
            </Typography>
            {avaliable ? (
               <Link to={link} target="_blank" style={{ textDecoration: "none" }}>
                  <Typography
                     sx={{ fontSize: { xs: "15px", md: "auto", "&:hover": { color: "#162c44" } } }}
                     color={"#5D6D7C"}
                     overflow={"hidden"}
                  >
                     <strong>Conozca más...</strong>
                  </Typography>
               </Link>
            ) : (
               <Link
                  to={link}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  onClick={() => {
                     const sectionSelector = link.split("#");
                     localStorage.setItem("selectedService", sectionSelector[1]);
                  }}
               >
                  <Typography
                     sx={{ fontSize: { xs: "15px", md: "auto", "&:hover": { color: "#162c44" } } }}
                     color={"#5D6D7C"}
                  >
                     <strong>Próximamente...</strong>
                  </Typography>
               </Link>
            )}
         </Stack>
      </Card>
   );
};

ModuleCard.propTypes = {
   icon: PropTypes.string,
   title: PropTypes.string,
   description: PropTypes.string,
   link: PropTypes.string,
   avaliable: PropTypes.bool,
};

export default ModuleCard;
