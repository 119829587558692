import { Box, Typography } from "@mui/material";
import { ThemeConfig } from "../../../../config/theme.config.js";
/* import { NavBar } from "../../../../components/NavBar.jsx"; */
import { useNavigate } from "react-router-dom";

export const GovernanceFormHeader = () => {
   const navigate = useNavigate();
   return (
      <ThemeConfig>
         <Box sx={{ width: "100%", padding: 0, borderRadius: 2 }}>
            {/* <NavBar /> */}

            <Box
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/bg-landing-form.png)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "650px",
                  display: "flex",
                  alignItems: "flex-start",
                  alignContent: "flex-start",
                  justifyContent: "flex-start",
                  textAlign: "center",
                  flexDirection: "column",
                  padding: "2rem",
               }}
            >
               <Box
                  onClick={() => navigate("/")}
                  component={"img"}
                  alt="Logo"
                  src={"https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy-blanco.png"}
                  sx={{
                     width: 133,

                     ml: 1,

                     "&:hover": {
                        cursor: "pointer",
                     },
                  }}
               />
               <Box
                  sx={{
                     padding: { xs: 2, sm: 4, md: 10 },
                     paddingTop: { xs: 10, md: 10 },
                     textAlign: { xs: "center", md: "start" },
                  }}
               >
                  <Typography
                     variant="h3"
                     sx={{
                        color: "#FFFFFF",
                        mb: { xs: 1, md: 2 },
                        mt: { xs: 2, sm: 14, md: 5 },

                        fontSize: { xs: "2rem", sm: "2.5rem", md: "2.8rem", lg: "3rem" },
                     }}
                  >
                     <strong> Reciba un análisis gratuito </strong>
                     <br />
                     acerca de su
                     <br />
                     <Typography
                        component="span"
                        sx={{
                           fontSize: { xs: "3rem", sm: "3rem", md: "3.5rem", lg: "4rem" },
                           display: "inline-block",
                           lineHeight: 1,
                           St: 0,
                        }}
                     >
                        <strong> gobernanza empresarial.</strong>
                     </Typography>
                  </Typography>

                  <svg
                     width="100%"
                     height="10"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     style={{ marginBottom: "16px" }}
                  >
                     <rect width="100%" maxWidth="400" height="4" fill="url(#paint0_linear_3476_524)" />
                     <defs>
                        <linearGradient
                           id="paint0_linear_3476_524"
                           x1="0"
                           y1="2.5"
                           x2="813"
                           y2="2.5"
                           gradientUnits="userSpaceOnUse"
                        >
                           <stop offset="0.0793503" stopColor="#8A95A1" />
                           <stop offset="0.462164" stopColor="#E8ECEF" />
                           <stop offset="1" stopColor="white" />
                        </linearGradient>
                     </defs>
                  </svg>

                  <Typography
                     variant="h6"
                     sx={{
                        marginTop: -1,
                        color: "#FFFFFF",
                        maxWidth: { md: 750, lg: 950 },

                        fontSize: { xs: "1rem", md: "1.3rem", lg: "1.5rem" },
                     }}
                  >
                     Nuestro diagnóstico le permitirá <strong>evaluar y mejorar sus prácticas actuales</strong>,
                     mientras que nuestra consultoría personalizada le{" "}
                     <strong>proporcionará las herramientas necesarias</strong> para implementar cambios significativos.
                  </Typography>
               </Box>
            </Box>
         </Box>
      </ThemeConfig>
   );
};
